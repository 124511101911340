import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import styles from "./style.module.scss";

const onClick = (onSelectImage, id, slug) => e => {
  e.preventDefault();
  onSelectImage(id, slug);
};
const GalleryItem = ({ image, slug, onSelectImage, id }) => (
  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
    <div className={styles.galleryThumb}>
      <a href={`/gallery/${slug}`} onClick={onClick(onSelectImage, id, slug)}>
        <Image
          alt={image.title}
          className={styles.galleryThumbImage}
          fluid={image.thumb}
          style={{ display: "block" }}
        />
      </a>
    </div>
  </div>
);

GalleryItem.propTypes = {
  id: PropTypes.string.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.shape({}).isRequired
};

export default GalleryItem;

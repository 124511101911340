import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import {
  get,
  getOr,
  pipe,
  filter,
  map,
  sortBy,
  cond,
  identity,
  stubTrue,
  isEmpty,
  negate,
  gt
} from "lodash/fp";

import searchGalleryItems from "./searchGalleryItems";
import GalleryItemList from "./GalleryItemList";

const query = graphql`
  {
    ...allGalleryItems
  }
`;

const scoreGreaterThan = val =>
  pipe(
    get("score"),
    gt(val)
  );

const noMatches = pipe(
  get("matches"),
  negate(isEmpty)
);

const processResult = filterKey => items => {
  return pipe(
    getOr([], "result.galleryItems"),
    cond([
      [
        () => filterKey,
        pipe(
          searchGalleryItems(filterKey),
          filter(noMatches),
          filter(scoreGreaterThan(0.65)),
          sortBy("score"),
          map("item")
        )
      ],
      [stubTrue, identity]
    ])
  )(items);
};

class GalleryItemListContainer extends React.Component {
  constructor(props) {
    super(props);
    const { selected } = props;
    this.state = { selected };
    this.onSelectImage = this.onSelectImage.bind(this);
    this.onClose = this.onClose.bind(this);
    this.deselect = this.deselect.bind(this);
  }

  onSelectImage(imageId, slug) {
    this.setState({ selected: imageId });
    const { navigate } = this.props;
    if (navigate) {
      setTimeout(() => {
        window.history.pushState(
          {
            prevUrl: window.location.href
          },
          null,
          `/gallery/${slug}`
        );
      }, 0);
    }
  }

  onClose() {
    this.setState({ selected: undefined });
    const { navigate } = this.props;
    if (navigate) {
      setTimeout(() => {
        const { history, location } = window;
        const prevUrl = getOr(false, "state.prevUrl")(history);
        if (prevUrl) {
          window.history.pushState(
            {
              prevUrl: location.href
            },
            null,
            prevUrl
          );
        } else {
          window.history.back();
        }
      }, 0);
    }
  }

  deselect() {
    this.setState({ selected: undefined });
  }

  render() {
    const { props } = this;
    const { filter: filterTag } = props;
    const { selected } = this.state;
    return (
      <StaticQuery
        query={query}
        render={data => (
          <GalleryItemList
            {...props}
            deselect={this.deselect}
            onClose={this.onClose}
            onSelectImage={this.onSelectImage}
            selected={selected}
            galleryItems={processResult(filterTag)(data)}
          />
        )}
      />
    );
  }
}

GalleryItemListContainer.propTypes = {
  selected: PropTypes.string,
  navigate: PropTypes.bool
};

GalleryItemListContainer.defaultProps = {
  selected: null,
  navigate: true
};

export default GalleryItemListContainer;

export { processResult };

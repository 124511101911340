import Fuse from "fuse.js";

const searchGalleryItems = query => items => {
  const fuse = new Fuse(items, {
    keys: [
      {
        name: "tags",
        weight: 1
      },
      {
        name: "title",
        weight: 1
      }
    ],
    threshold: 0.65,
    distance: 10000,
    minMatchCharLength: 4,
    useExtendedSearch: true,
    includeMatches: true,
    includeScore: true
  });
  return fuse.search(query);
};

export default searchGalleryItems;

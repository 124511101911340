import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "gatsby-image";
import slugify from "slugify";
import { find } from "lodash/fp";
import { H2 } from "../Type";
import styles from "./style.module.scss";

const SelectedItem = ({ galleryItems, selected, onClick }) => {
  if (!selected) return null;
  const selectedGalleryItem = find({ id: selected }, galleryItems);
  if (!selectedGalleryItem) return null;
  const { title, image, tags } = selectedGalleryItem;
  return (
    <div>
      <H2>{title}</H2>
      <Image
        className={styles.selectedItem}
        fluid={image.fluid}
        style={{ display: "block" }}
      />
      {tags.map(tag => (
        <Link
          key={tag}
          className={styles.tag}
          to={`/gallery?tag=${slugify(tag.toLowerCase())}`}
          onClick={onClick}
        >
          {tag.toLowerCase()}
        </Link>
      ))}
    </div>
  );
};

SelectedItem.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  galleryItems: PropTypes.arrayOf(PropTypes.shape).isRequired
};

SelectedItem.defaultProps = {
  selected: null
};

export default SelectedItem;

import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import GalleryItem from "./GalleryItem";
import SelectedItem from "./SelectedItem";

const GalleryItemList = ({
  galleryItems,
  onSelectImage,
  selected,
  deselect,
  onClose
}) => (
  <div className="row">
    {galleryItems.map(galleryItem => (
      <GalleryItem
        key={galleryItem.id}
        {...galleryItem}
        onSelectImage={onSelectImage}
      />
    ))}
    <Modal visible={selected !== null} onClose={onClose}>
      <SelectedItem
        galleryItems={galleryItems}
        selected={selected}
        onClick={deselect}
      />
    </Modal>
  </div>
);

GalleryItemList.propTypes = {
  selected: PropTypes.string,
  deselect: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  galleryItems: PropTypes.arrayOf(PropTypes.shape).isRequired
};

GalleryItemList.defaultProps = {
  selected: null
};

export default GalleryItemList;
